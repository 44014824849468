import {findAllByDisplayValue} from "@testing-library/react";

export interface Submission {
    type: 'ISSUE' | 'SUGGESTION' | 'LILIYA';
    title: string;
    author: string;
    category: string;
    idea: React.ReactNode;
    answer?: React.ReactNode;
}

export const SUBMISSIONS: Submission[] = [
    {
        type: 'SUGGESTION',
        title: 'Themed Meeting Rooms',
        author: 'Danny Hobo',
        category: 'Office',
        idea: <div>
            <p>
                Our meeting rooms could use more personality. At the moment, they only have a sign outside indicating
                the room name and its associated city. It would be great if the interior of each room also reflected its
                name, creating a more immersive and engaging atmosphere.
            </p>
            <p>Some ideas:</p>
            <ul>
                <li>Paint the room in the colors of the country the city belongs to (e.g., Tallinn: black-blue-white,
                    Oslo: red-white-blue, etc.).
                </li>
                <li>Hang photos inside the room that represent the sights of the city.</li>
                <li>Install a digital frame showing fun facts about the city, which can be regularly updated.</li>
            </ul>
        </div>
        ,
        answer: <div><p>Great idea!</p></div>,
    },
    {
        type: 'SUGGESTION',
        title: 'Enhancing BetBuilder Potential',
        author: 'Shane Gordon',
        category: 'Tools',
        idea: <div>
            <p>
                Revamping of our BetBuilder Tool with an in-house solution.
            </p>
            <p>
                I would like to start by acknowledging that this is just an idea as I have no insight on how challenging
                or realistic the possibility of this is, as I am not an oddsmaker or a developer, but I am a dreamer!
            </p>
            <p>
                After seeing the implementation of our Combo Card tool, it really got me thinking that this tool can be
                developed into something greater and implemented as a Bet Builder/Same Game Parlay tool. Its ability to
                pull odds from our site and combine them into one parlay is very intriguing. With the growth of Same
                Game Parlays in North America and it being a large reason why NA operators like FanDuel and DraftKings
                are doing so well, this gives us a channel to compete on that level.
            </p>
            <p>
                I understand that there is an element of how related odds need to be taken into account for same game
                offerings, and that is where we would need someone with a much, much higher mathematical comprehension
                than myself to step in. However, I see the potential in the Combo Card tool as it has the ability to
                Boost (+) odds, but it also has the ability to minus or negative boost odds (-), which would address
                this relativity concern. I wish I could attach pictures to show my thought process a little more
                eloquently.
            </p>
            <p>
                Hearing real-life insights from our VIP bettors that our BetBuilder product is inferior to our North
                American competitors, I think this is a chance to really develop a tool that can create growth in the
                Canadian SB market. Team Canada are more than willing to be a guinea pig on this endeavor.
            </p>
            <p>
                As always, I am happy to provide my thoughts and ideas and hope that we can take this to the next level.
            </p>
        </div>
        ,
        answer: <div><p>This is a great idea and our product and IT teams are already working to create new and improved Betbuilder solution for Coolbet. It is estimated to launch early 2025.</p></div>,
    },
    {
        type: 'ISSUE',
        title: 'Backoffice Woos',
        author: 'Aleksandr Fomichov',
        category: 'Tools',
        idea: <div>
            <p>
                Backoffice CMS Articles (Promo pages): Creation, Translation, Editing.
            </p>
        </div>

        ,
        answer: <div><p>These modules will be re-prioritized for the 2025 roadmap to ensure that we finally improve them.</p></div>,
    },
    {
        type: 'ISSUE',
        title: 'Optimizing Inter-Team Processes',
        author: 'Edvard Kingissepp',
        category: 'Processes',
        idea: <div>
                <p>
                    Communication with other teams within the company is currently very slow (IT, UI/UX, etc.). Simple fix requests or new implementations can take weeks or even months to complete.
                </p>
                <p>
                    Some necessary implementations have the potential to generate revenue for the business; however, the sluggish processes hinder this progress.
                </p>
            </div>
        ,
        answer: <div><p>Agree that sometimes there's a lack of a clear process how certain improvements can be taken from idea to completion.</p></div>,
    },
    {
        type: 'SUGGESTION',
        title: 'AI-Powered Bet Assistance',
        author: 'Murat Simsek',
        category: 'Website',
        idea: <div>
            <p>
                <strong>Bet Assistance</strong> - This AI-based Bet Assistance provides a comprehensive explanation of
                the best possible outcome for a sportsbook event. AI selects the best option (e.g., home team winner,
                away wins by 2 handicap, total goals over 4.5, etc.) based on real statistics from previous games.
            </p>
            <p>
                This assistance will help our products generate more turnover and traffic. Imagine a user with little
                knowledge about team X—AI Assistance suggests an outcome with a proper explanation based on gathered
                statistics. The user would feel convinced to place their bet on a game they knew nothing about just two
                minutes earlier.
            </p>
            <p>
                Don’t worry about wins; sportsbooks are all about increasing traffic and turnover. At the end of the
                day, losses will balance everything out.
            </p>
        </div>

        ,
        answer: <div><p>Can BI make it happen? 😉 </p></div>,
    },
    {
        type: 'SUGGESTION',
        title: 'Revitalizing Our Marketing Campaigns',
        author: 'Edvard Kingissepp',
        category: 'Other',
        idea: <div>
            <p>
                From my perspective, our current marketing campaigns seem to be stagnating due to a lack of innovation.
                There's an apparent hesitation to experiment with fresh ideas or take calculated risks, resulting in the
                repeated use of the same campaigns, even when their performance falls short of expectations.
            </p>
            <p>
                This reliance on familiar methods not only limits our potential for growth but also hinders our ability
                to engage customers in new and dynamic ways. To remain competitive, we should be more open to creative
                solutions and adaptable strategies that can enhance our overall performance.
            </p>
            <p>
                When we introduced new marketing approaches in Estonia, the results were almost immediately impressive.
                This success highlights how much our players appreciate fresh and innovative campaigns.
            </p>
        </div>
        ,
        answer: <div><p>Fresh ideas and suggestions are always welcome. Failing is also acceptable, as long as we try new things.</p></div>,
    },
    {
        type: 'SUGGESTION',
        title: 'Make Betslip Matches Clickable',
        author: 'Joseph Villeneuve',
        category: 'Website',
        idea: <div>
            <p>
                Matches in the betslip should be clickable. Clicking on a match would not erase anything in the betslip
                itself but would bring the customer back to that match.
            </p>
            <p>
                Similarly, matches shown in Combo Cards at the top of the page should be clickable as well and lead to
                their respective matches.
            </p>
        </div>
        ,
        answer: <div><p>Reading it makes it feel so obvious, that we are surprised it hasn't been done yet!</p></div>,
    },
    {
        type: 'ISSUE',
        title: 'Revamping the Bet Builder Experience',
        author: 'Henri Friman',
        category: 'Website',
        idea: <div>
            <p><strong>Idea for Bet Builder:</strong></p>

            <p><strong>Problem:</strong> Our Bet Builder feels very old and tacky. It is not Coolbet style to offer a
                bad solution to customers.</p>

            <p><strong>Idea:</strong> Offering an easier way to build bets should bring us happier customers and more
                combo bets vs single bets --&gt; more profit.</p>

            <p><strong>Competitors' Bet Builder:</strong> On most betting sites (Unibet/PAF, Nordicbet/Betsson/Betsafe,
                Bet365), customers can nowadays choose multiple different bets from one game. For example, over 2.5
                goals and home team to win. Customers can place a combo bet for these two bets without having to open a
                Bet Builder tool. The system automatically recognizes it as a Bet Builder bet when there are two or more
                selections on the same bet slip.</p>

            <p><strong>Our Bet Builder:</strong> On Coolbet, if you try to bet on two outcomes from a single match, the
                system notifies you that it is not possible. You need to know that there is a place called Bet Builder
                tool where you can go to place these kinds of bets. Otherwise, you can't do it. The system does not
                automatically offer the Bet Builder solution—it just announces: <em>COMBO NOT ALLOWED</em>.</p>

            <p>Even if you knew you could go to the Bet Builder tool to place the bet, it is much harder for the
                customer to go there and place the bet than just placing the bet in the normal betting tool.</p>

            <p>In addition, our Bet Builder tool feels like it is from 2009. It is not even in the same league as our
                competitors' Bet Builder tools.</p>

            <p><strong>Suggestion:</strong> Please take a look at how our competitors are doing it and copy it :)</p>

            <p><em>Ps. I got the idea from a guy working at Epicbet. He told me they have the same kind of bad version
                of Bet Builder as we do, but they are going to change it for the modern solution "soon."</em></p>
        </div>
        ,
        answer: <div><p>This is a great idea and our product and IT teams are already working to create new and improved Betbuilder solution for Coolbet. It is estimated to launch early 2025.</p></div>,
    },
    {
        type: 'LILIYA',
        title: '1. Boosting Visibility: An SEO Strategy',
        author: 'Liliya Hermanovych',
        category: 'Other',
        idea: <div className="summary-section">
            <p>Below is a summary, full submission is available <a href="https://docs.google.com/document/d/10p8b9cEe7w8wWjXgnd6eV6d00teKfQoOqZwSA0OwsDs/edit?tab=t.0" target="_blank" className="regular-link" rel="noreferrer">here</a>.</p>

            <p>Coolbet's online presence is lacking in organic search results, hindering its ability to attract new
                players. A comprehensive SEO strategy, utilizing relevant keywords and consistent, high-quality content
                creation, is crucial to improve Google search rankings and drive organic traffic to the website.</p>

            <b>Key Issues:</b>
            <ol>
                <li><strong>Poor Google Ranking:</strong> Coolbet is virtually invisible in Google searches for many key
                    terms related to online casinos, live casino games, and online poker in Estonia. This severely
                    limits the company's visibility to potential new customers.
                </li>
                <li><strong>Outdated Content:</strong> Existing blog content is infrequently updated and contains
                    outdated information, which reflects poorly on the brand and harms SEO efforts.
                </li>
                <li><strong>Scattered Resources:</strong> Valuable content, such as the Safer with Coolbet videos, is
                    hidden within the website's policies section, making it difficult for users to find.
                </li>
            </ol>

            <b>Recommendations:</b>
            <ol>
                <li><strong>Develop a Robust Blog:</strong> Create a centralized blog space with regularly updated,
                    informative, and engaging content that targets relevant keywords. This content should include game
                    guides, betting strategies, responsible gambling tips, and promotions of games with low RTP or
                    unique features.
                </li>
                <li><strong>Keyword Optimization:</strong> Conduct thorough keyword research to identify high-value
                    search terms and strategically incorporate them into page titles, headings, content, image alt text,
                    and meta descriptions. Focus on keywords with strong purchase intent, location-based keywords, and
                    long-tail keywords.
                </li>
                <li><strong>Content is King:</strong> Prioritize the creation of high-quality content that answers
                    player questions, provides valuable insights, and encourages users to stay on the Coolbet website.
                </li>
                <li><strong>Regular Updates:</strong> Consistently publish fresh content to demonstrate to Google that
                    the site is active and relevant. Leverage AI tools like Gemini to streamline content creation and
                    minimize manual effort.
                </li>
                <li><strong>Performance Monitoring:</strong> Utilize Google Analytics and Search Console to track
                    website traffic, monitor keyword performance, and identify areas for improvement.
                </li>
            </ol>

            <p>By implementing these recommendations, Coolbet can significantly enhance its organic search visibility,
                attract new players, and establish a stronger online presence.</p>
        </div>
        ,
    },
    {
        type: 'LILIYA',
        title: '2. Engaging Players: An Exclusive Instagram Strategy',
        author: 'Liliya Hermanovych',
        category: 'Other',
        idea: <div className="summary-section">
            <p>Below is a summary, full submission is available <a href="https://docs.google.com/document/d/1ounfXplfMmZ90GBWanVAaAIBxSeGYzJ-8f9wuPhAnAg/edit?tab=t.0" target="_blank" className="regular-link" rel="noreferrer">here</a>.</p>

            <p>This proposal outlines a strategy to engage active and dormant Coolbet players by creating a private,
                exclusive Instagram group. This approach addresses the declining effectiveness of traditional
                communication channels like SMS and email by leveraging Instagram's high engagement rates and direct
                access to a receptive audience.</p>

            <p><b>Key Features:</b></p>
            <ol>
                <li><b>Exclusivity:</b> The group will be accessible only to verified, loyal "VIP" players who meet
                    specific criteria, such as wagering thresholds or participation in promotions.
                </li>
                <li><b>Targeted Content:</b> The group will feature exclusive bonuses, early access to promotions,
                    behind-the-scenes content, tournaments, and giveaways tailored to the interests of its members.
                </li>
                <li><b>Community Building:</b> The group will foster a sense of belonging and loyalty among its members,
                    encouraging interaction and engagement with the brand.
                </li>
                <li><b>Optimized Marketing Spend:</b> By focusing on high-value players, this strategy aims to maximize
                    customer lifetime value and increase retention rates.
                </li>
                <li><b>Privacy Compliance:</b> The group will operate on an opt-in basis, ensuring compliance with data
                    privacy regulations and user consent.
                </li>
            </ol>

            <p><b>Content Strategy:</b></p>
            <ol>
                <li>Leverage psychological effects like "loss aversion" and the "bandwagon effect" to encourage
                    engagement.
                </li>
                <li>Utilize storytelling and emotional appeals to connect with the audience.</li>
                <li>Promote games with low RTP or win potential through engaging content.</li>
                <li>Showcase winners and their experiences to inspire others.</li>
            </ol>

            <p>By implementing this strategy, Coolbet can enhance player engagement, foster loyalty, and optimize
                marketing spend while maintaining a privacy-compliant approach.</p>
        </div>

        ,
    },
    {
        type: 'LILIYA',
        title: '3. A Diversified VIP Loyalty Program',
        author: 'Liliya Hermanovych',
        category: 'Other',
        idea: <div className="summary-section">
            <p>Below is a summary, full submission is available <a href="https://docs.google.com/document/d/1RU2zb1GiVdFuDdMD0Loj5YtqpsQ6o2hdL6dVo0r2e-w/edit?tab=t.0" target="_blank" className="regular-link"
                                                                   rel="noreferrer">here</a>.</p>

            <p>This document outlines a diversified loyalty program for Coolbet's VIP customers, moving beyond the
                traditional focus on high rollers to encompass a wider range of player activity. The program aims to
                enhance player retention, boost customer lifetime value, and encourage brand advocacy.</p>

            <p><b>Key Features:</b></p>
            <ol>
                <li><b>Tiered System:</b> Players progress through various VIP levels (e.g., Sapphire, Emerald, Ruby,
                    Gold, Platinum, Diamond) by accumulating points based on their engagement.
                </li>
                <li><b>Diverse Criteria:</b> Points are awarded for various actions, including total deposits, wagering
                    volume, number of bets, game-specific activity, referrals, and tournament rankings.
                </li>
                <li><b>Exclusive Rewards:</b> Each VIP tier unlocks increasingly valuable rewards, such as exclusive
                    bonuses, personalized offers, higher deposit limits, Coolbet merchandise, and invitations to
                    exclusive events.
                </li>
                <li><b>Synergy with Other Initiatives:</b> The VIP program is designed to work in conjunction with other
                    engagement strategies, such as the proposed private Instagram group for loyal players.
                </li>
            </ol>

            <p><b>Benefits:</b></p>
            <ul>
                <li><b>Increased Player Retention:</b> Motivates players to remain loyal and strive for higher VIP
                    tiers.
                </li>
                <li><b>Higher CLTV:</b> Encourages players to spend more and play longer, increasing their lifetime
                    value.
                </li>
                <li><b>Brand Advocacy:</b> Creates satisfied, loyal players who are more likely to recommend Coolbet to
                    others.
                </li>
                <li><b>New Game Promotion:</b> Provides a platform to promote new games, even those with low RTP, to a
                    receptive audience.
                </li>
            </ul>

            <p>By implementing a comprehensive and diversified VIP program, Coolbet can cultivate a loyal player base,
                drive revenue growth, and strengthen its brand presence in the competitive online gaming market.</p>
        </div>

        ,
    },
    {
        type: 'LILIYA',
        title: '4. Showcasing recent casino winners',
        author: 'Liliya Hermanovych',
        category: 'Website',
        idea: <div className="summary-section">
            <p>Below is a summary, full submission is available <a href="https://docs.google.com/document/d/1a27yk_Mc-cTsOW_iwFhRvLie83-sejmVWhGdM9_vPCM/edit?tab=t.0" target="_blank" className="regular-link"
                                                                   rel="noreferrer">here</a>.</p>

            <p>Displaying recent winners of tournaments and casino games on the Coolbet platform offers numerous
                advantages:</p>

            <p><b>1) Social Proof:</b> Seeing others win creates excitement and motivates players by demonstrating that
                winning is possible.</p>
            <p><b>2) Community Building:</b> Sharing wins fosters a sense of community and encourages interaction among
                players.</p>
            <p><b>3) Marketing Tactic:</b> Showcasing winners acts as a form of promotion, highlighting the potential
                for winnings on Coolbet.</p>
            <p><b>4) Psychological Influence:</b> Leverages the "bandwagon effect," encouraging players to participate
                by showing the success of others.</p>

            <p><b>Implementation Considerations:</b></p>
            <ol>
                <li><b>Anonymize Usernames:</b> Protect player privacy by anonymizing usernames or using initials.</li>
                <li><b>Exclude Restricted Regions:</b> Exclude jurisdictions like Sweden and Peru due to responsible
                    gambling and licensing restrictions.
                </li>
                <li><b>Accurate Information:</b> Ensure displayed wins are accurate and not misleading to maintain
                    transparency and trust.
                </li>
            </ol>

            <p><b>Examples:</b></p>
            <ul>
                <li>Display recent wins and losses in bets.</li>
                <li>Highlight high rollers and their achievements.</li>
                <li>Showcase recent jackpot winners.</li>
                <li>Feature winners of promotional campaigns.</li>
            </ul>

            <p>By prominently featuring winners on the platform, Coolbet can enhance player engagement, build a stronger
                community, and effectively promote its offerings while adhering to responsible gambling practices.</p>
        </div>

        ,
    },
    {
        type: 'LILIYA',
        title: '5. Highlighting Jackpot Potential',
        author: 'Liliya Hermanovych',
        category: 'Website',
        idea: <div className="summary-section">
            <p>Below is a summary, full submission is available <a href="https://docs.google.com/document/d/1TljcxTwoiydZi0ooxX_ml2wg7t6NQihZGxwdqRREzAQ/edit?tab=t.0" target="_blank" className="regular-link"
                                                                   rel="noreferrer">here</a>.</p>

            <p>Currently, the display of potential jackpot winnings on the Coolbet platform is not prominent enough to
                attract significant player attention. This document proposes several strategies to enhance the
                visibility of jackpot information:</p>

            <p><b>Visual Prominence:</b></p>
            <ul>
                <li><b>Banners:</b> Utilize eye-catching banners on the casino page and potentially the homepage to
                    showcase current jackpot amounts.
                </li>
                <li><b>Dedicated Jackpot Section:</b> Create a dedicated section for jackpot games on the homepage or
                    casino page.
                </li>
                <li><b>Game Lobby Highlights:</b> Highlight jackpot games within the game lobby using markers,
                    animations, or visual cues.
                </li>
            </ul>

            <p><b>Dynamic Jackpot Counters:</b></p>
            <ul>
                <li><b>Real-Time Updates:</b> Display dynamic counters that constantly update the growing jackpot
                    amounts, creating a sense of urgency and excitement.
                </li>
            </ul>

            <p><b>Regional Considerations:</b></p>
            <ul>
                <li><b>Responsible Gaming Countries:</b> For countries like Sweden and Peru, jackpot information should
                    be displayed responsibly and in line with licensing requirements.
                </li>
                <li><b>Other Regions:</b> Maximize jackpot visibility to encourage play and excitement.</li>
            </ul>

            <p><b>Displaying Usernames:</b></p>
            <ul>
                <li><b>Tournament Terms and Conditions:</b> To display usernames without anonymization, include specific
                    terms and conditions in tournaments that allow for the display of chosen display names, regions, and
                    points on leaderboards.
                </li>
            </ul>

            <p>With all this, Coolbet can effectively highlight potential jackpot winnings, increasing player engagement
                and driving interest in jackpot games.</p>
        </div>

        ,
    },
    {
        type: 'LILIYA',
        title: '6. Holiday Marketing',
        author: 'Liliya Hermanovych',
        category: 'Other',
        idea: <div className="summary-section">
            <p>Below is a summary, full submission is available <a href="https://docs.google.com/document/d/1teFR414vNPT8QpMjiMa0aXEXIPzgXXpJGLaNndwy020/edit?tab=t.0" target="_blank" className="regular-link"
                                                                   rel="noreferrer">here</a>.</p>

            <p>Holidays present a valuable opportunity to boost player engagement and promote thematic casino games on
                the Coolbet platform. This strategy capitalizes on the festive mood and increased spending associated
                with holidays.</p>

            <p><b>Key Actions:</b></p>
            <ul>
                <li><b>Increase Visibility:</b> Make holiday-themed games more prominent in the casino lobby, homepage,
                    and filter options on the left sidebar.
                </li>
                <li><b>Organize Tournaments:</b> Create holiday-themed tournaments, missions, and challenges to
                    encourage players to try different games.
                </li>
                <li><b>Leverage Social Media:</b> Utilize the private Instagram group for loyal customers to promote
                    holiday-themed activities and exclusive offers.
                </li>
            </ul>

            <p><b>Benefits:</b></p>
            <ul>
                <li><b>Increased Engagement:</b> Capitalize on the festive spirit and encourage players to explore new
                    games.
                </li>
                <li><b>Promote New Games:</b> Introduce and promote holiday-themed games, even those with lower RTP.
                </li>
                <li><b>Drive Revenue:</b> Leverage the increased spending associated with holidays to drive revenue
                    growth.
                </li>
                <li><b>Enhance Community:</b> Create a sense of community and shared excitement around holiday events.
                </li>
            </ul>

            <p><b>Examples of Holidays to Utilize:</b></p>
            <ul>
                <li>Halloween, Christmas, New Year's Eve, St. Patrick's Day, Valentine's Day, Easter, Thanksgiving,
                    Summer Holidays, Oktoberfest, and other international holidays.
                </li>
            </ul>

            <p>This way we could effectively leverage holidays to enhance player engagement, promote thematic games, and
                drive revenue growth.</p>
        </div>

        ,
    },
    {
        type: 'LILIYA',
        title: '7. A Monthly "Refer a Friend" Competition',
        author: 'Liliya Hermanovych',
        category: 'Other',
        idea: <div className="summary-section">
            <p>Below is a summary, full submission is available <a href="https://docs.google.com/document/d/1rlQ9r73W90vDNwIX7TkFezDN97D7KVx4Vw7PPY-qwqM/edit?tab=t.0" target="_blank" className="regular-link"
                                                                   rel="noreferrer">here</a>.</p>

            <p>Here is a strategy to implement a monthly <b>"Refer a Friend"</b> competition, offering a compelling
                alternative to lifetime referral bonuses. The competition aims to drive player acquisition and boost
                engagement through several key features:</p>

            <ul>
                <li><b>Visible Progress:</b> Real-time tracking of referrals will be displayed on a leaderboard,
                    fostering competition and encouraging increased participation.
                </li>
                <li><b>Attractive Prizes:</b> Multiple prize tiers, including monetary rewards and cashless prizes like
                    free bets and merchandise, will incentivize players.
                </li>
                <li><b>Social Media Integration:</b> Promote the competition through social media channels, particularly
                    the exclusive Instagram group for loyal players.
                </li>
                <li><b>Strategic Timing:</b> Align competition timelines with salary days in specific countries to
                    capitalize on increased spending potential.
                </li>
                <li><b>Regional Optimization:</b> Focus on regions like LATAM where social referral behavior is more
                    prevalent.
                </li>
                <li><b>Compliance:</b> Ensure compliance with data protection regulations by including specific terms
                    and conditions for participation and username display.
                </li>
            </ul>

            <p><b>Benefits:</b></p>
            <ul>
                <li><b>Cost-Effective Acquisition:</b> Offers a more cost-effective alternative to lifetime referral
                    bonuses.
                </li>
                <li><b>Enhanced Engagement:</b> Drives engagement by fostering competition and social interaction.</li>
                <li><b>Increased Acquisition:</b> Incentivizes players to refer friends, expanding the player base.</li>
                <li><b>Targeted Approach:</b> Optimizes campaigns by aligning them with salary days and regional
                    preferences.
                </li>
            </ul>
        </div>

        ,
    },
    {
        type: 'LILIYA',
        title: '8. Optimizing Campaign Timing',
        author: 'Liliya Hermanovych',
        category: 'Other',
        idea: <div className="why-section">
            <p><b>Why?</b></p>
            <ul>
                <li><b>Increased Spending Potential:</b> Players are more likely to have disposable income immediately
                    after receiving their salary, making them more receptive to gambling offers and tournaments.
                </li>
                <li><b>Psychological Effect:</b> Payday often brings a sense of reward and a willingness to spend, which
                    can be leveraged by Coolbet.
                </li>
                <li><b>Higher Engagement:</b> Players might be more motivated to participate in tournaments and
                    promotions when they have readily available funds.
                </li>
            </ul>

            <p>When ‘the pot is empty’ - there’s not much sense in running campaigns and bonuses.</p>

            <p><b>Factors to Consider:</b></p>
            <ol>
                <li><b>Payday Frequency:</b> Understand the typical payday schedule in your target market (e.g., weekly,
                    bi-monthly, monthly).
                    <p>In every country, the period when salaries are paid—and when players may be most active—differs.
                        For instance:</p>
                    <ul>
                        <li>In Ukraine, salaries are typically paid at least twice a month, on the 7th and 21st. Running
                            a campaign on the 15th or 30th would be less effective than in the first few days after
                            wages are received.
                        </li>
                        <li>In Estonia, if salaries are paid monthly on the 5th, most campaigns (unless holiday-based)
                            should be scheduled just after payday.
                        </li>
                    </ul>
                </li>
                <li><b>Analytics Insights:</b> Use Google Analytics to identify the days when players in a particular
                    country are most active.
                </li>
                <li><b>Timezone Relevance:</b> Post at the most optimal time (based on Analytics) when the audience is
                    most active. For example:
                    <ul>
                        <li>LATAM requires a specific time zone strategy.</li>
                        <li>The EU requires a different time zone focus.</li>
                    </ul>
                </li>
            </ol>
        </div>

        ,
    },
    {
        type: 'LILIYA',
        title: '9. Obligatory Opt-in',
        author: 'Liliya Hermanovych',
        category: 'Website',
        idea: <div>
            <p><strong>WHY?</strong></p>
            <ul>
                <li><strong>Targeted Marketing:</strong> Opt-ins are crucial to make all our marketing through various
                    channels the most resultful and to work as intended on as many customers as possible.
                </li>
                <li><strong>Freely Given Consent:</strong> All players may manage their opt-in and opt-out choices in
                    their account page by choosing separate the preferred communication method: email, direct email,
                    sms, phone. For players, opt-in consent should be freely given, specific, informed, and unambiguous.
                    As long as players may make their choices under their account at any time without any coercion or
                    disadvantage (they can do any bets, play any games they want), the requirement is fulfilled.
                </li>
            </ul>

            <p>But when conducting a bonus campaign, we are the ‘rule makers’, so we define the rules of our bonus game
                and who from the players will be eligible to receive additional bonuses. As participation in this
                campaign is absolutely voluntary, non-obligatory - by offering bonuses only to players who have opted
                in, we do not break the requirement of freely given consent. As long as players have a genuine choice to
                opt-in or opt-out of marketing communications without any coercion or disadvantage, the "freely given"
                element of consent is maintained.</p>

            <p><strong>WHAT IS IMPORTANT!</strong></p>
            <ul>
                <li><strong>Transparency:</strong> To change our T&C of BONUS rules <a
                    href="https://www.coolbet.com/en/promotions/rules/sports-bonus-rules" target="_blank" rel="noreferrer">here</a> so
                    that players are clearly communicated about the rules and requirements for bonus participation,
                    including the opt-in requirement in the bonus BEFORE applying for the bonus.
                </li>
                <li>Use to the maximum extent possible exclusive bonus offers from our Casino game providers to make it
                    a ‘zero lost’ bonus campaign: beneficial for us since lots of players opt-in, bonuses promote gaming
                    providers and are basically covered by them (we are not the ones who will pay at the end of the
                    line), and usually used only in games of such gaming providers.
                </li>
            </ul>

            <p><strong>Approach used by our competitors actively:</strong></p>
            <ul>
                <li><strong>Betsafe:</strong> "A single opt-in is required for the duration of this promotion. To opt-in
                    you simply need to open a promotional game and start playing. Should you not wish to participate,
                    once you open a promotional game, an in-game pop-up window will appear giving you the option to
                    opt-out."
                </li>
                <li><strong>Vivatbet:</strong> Before making a deposit, you must opt in to receiving casino bonuses in
                    My Account in Account Settings.
                </li>
                <li><strong>Leon:</strong> Dare to "Opt-in" and play selected Pragmatic Play slots in the 7 Network
                    Prize Draws from October 28 to November 3.
                </li>
            </ul>
        </div>

        ,
    },
    {
        type: 'LILIYA',
        title: '10. Social Media Opt-in',
        author: 'Liliya Hermanovych',
        category: 'Website',
        idea: <div>
            <p><strong>WHY?</strong></p>
            <p>Currently, we have 4 opt-in options: sms, email, direct mail, and phone. This actually deprives us of the
                possibility to use social media advertising to the full extent (especially regarding dormant
                players).</p>

            <p><strong>HOW?</strong></p>
            <p>To add a ‘social media platforms’ button in subscription settings.</p>

            <p><strong>BUT!</strong></p>
            <p>As default, under the GDPR requirements, it will be ‘off’ (opt-out) even when all other communications
                were previously opted-in by the user. The player is the only one who can make it active (opt-in) since
                the requirements of the consent are specific and informed, freely given by the user.</p>

            <p>The goal: To actively use point 9 (9. Make obligatory opt-in to participate in any of our bonus
                campaigns) and bonus campaigns as an effective tool to make users opt-in.</p>

            <p>So, if the player wants to participate in the campaign and had previously opted in for 4 available tools
                but not for the new one ‘social media platforms’, then he/she will not be able to participate until the
                last opt-in is activated.</p>

            <p>+ Make a push notification about the new method of communication (active for a certain period (1 month)
                and displayed until the opt-in is received) for everyone who logs in to their account.</p>
        </div>
    },
    {
        type: 'LILIYA',
        title: '11. Bonus participation limits',
        author: 'Liliya Hermanovych',
        category: 'Website',
        idea: <div>
            <p><strong>Limit the participation in bonus promotion:</strong> once per account, person, family, household,
                address, e-mail address, credit card number and/or IP address.</p>

            <p><strong>WHY?</strong></p>
            <p>Fight the ‘gnomming’: limit players' misuse of bonuses, matched/arbitrage betting. Any bonuses received
                during this campaign are limited to one per client, household, registration address, e-mail, or IP
                address. If an attempt by the customer to open a duplicate account is revealed, all bonuses will be
                removed from all accounts belonging to that customer.</p>

            <p>The purpose is to have a ‘healthy’ base of loyal customers and not ghosts who will create accounts for
                all their family members residing at the same address just to get the bonus. After getting the bonus,
                those gnommed ‘ghost users’ will, of course, not continue to play and will disappear until the next
                bonus.</p>

            <p><strong>Fair play:</strong> to create a more level playing field for genuine players who are not trying
                to exploit the system.</p>

            <p><strong>Examples:</strong></p>
            <ul>
                <li><strong>Betsafe:</strong> The promotion can only be entered into once per account, person, family,
                    household, address, e-mail address, credit card number and/or IP address.
                </li>
                <li><strong>Leon:</strong> 10.2. Any bonuses received during this campaign are limited to one per
                    client, household, registration address, e-mail, or IP address. We reserve the right to terminate or
                    hide the bonus campaign at any time and immediately terminate the player account should we suspect
                    bonus abuse has occurred.
                </li>
            </ul>
        </div>
        ,
    },
    {
        type: 'LILIYA',
        title: '12. Annual Bonus Campaign',
        author: 'Liliya Hermanovych',
        category: 'Website',
        idea: <div>
            <p>Below is a summary, full submission is available <a href="https://docs.google.com/document/d/19TZMRt4DSWpb27FJ6eO2AlabDIK_iu2aYpZuODPyiwI/edit?tab=t.0" target="_blank" className="regular-link" rel="noreferrer">here</a>.
            </p>

            <p><strong>The campaign aims to:</strong></p>
            <ul>
                <li><strong>Incentivize Higher Spending:</strong> A tiered deposit requirement encourages players to
                    deposit more throughout the year to qualify for bigger bonuses, increasing customer lifetime value.
                </li>
                <li><strong>Promote Combo Bets:</strong> Focus on combo bets to increase the sportsbook's hold (profit
                    margin) due to their lower probability of winning.
                </li>
                <li><strong>Reward Loyal Players:</strong> Offer exclusive rewards to genuine loyal players,
                    discouraging bonus abusers.
                </li>
            </ul>

            <p><strong>Campaign Mechanics:</strong></p>
            <ul>
                <li><strong>Exclusive and Time-Limited:</strong> Run the campaign once a year at a fixed time, creating
                    anticipation and exclusivity.
                </li>
                <li><strong>Visible and Accessible:</strong> Promote the campaign year-round on the website and through
                    periodic push notifications.
                </li>
                <li><strong>Bonus Options:</strong> Offer a choice of bonus types (sportsbook, casino, poker) to cater
                    to different player preferences.
                </li>
                <li><strong>Opt-in Requirement:</strong> Make the campaign opt-in to enable targeted marketing and
                    identify engaged players.
                </li>
                <li><strong>Regional Adjustments:</strong> Tailor annual deposit requirements to different countries'
                    spending patterns.
                </li>
            </ul>

            <p><strong>Considerations:</strong></p>
            <ul>
                <li><strong>Timing:</strong> Choose a consistent time for the campaign, considering factors like player
                    birthdays, holidays, and potential impact on Q4 results.
                </li>
                <li><strong>Bonus Type:</strong> Combo bets sports bonuses are recommended due to their higher
                    profitability, but offering player choices may be considered.
                </li>
            </ul>

            <p>By implementing this annual bonus campaign, Coolbet can effectively incentivize higher spending, promote
                combo bets, reward loyal players, and drive overall customer lifetime value.</p>
        </div>
        ,
    },
    {
        type: 'LILIYA',
        title: '13. Visibility of payments and safety',
        author: 'Liliya Hermanovych',
        category: 'Website',
        idea: <div>
            <p><strong>‘Payment methods’ and ‘Safer with Coolbet’ – more visible to users</strong></p>

            <p>We have developed AMAZING ‘Payment methods’ and ‘Safer with Coolbet’ pages,</p>

            <p><strong>BUT:</strong> they are not visible from the home page, ‘buried’ in the Support section. I saw
                them only because I repeatedly look into the Privacy Notice. But an average user does not read T&C and
                Privacy Notice, they ask Customer Service via live chat and not email. Therefore, it’s unlikely they
                will see those two pages.</p>

            <p>Competitors usually have Payment methods at the bottom of the Home Page.</p>
        </div>
        ,
    },
    {
        type: 'LILIYA',
        title: '14. Maximizing LinkedIn for Coolbet',
        author: 'Liliya Hermanovych',
        category: 'Other',
        idea: <div>
            <p>Below is a summary, full submission is available <a href="https://docs.google.com/document/d/1ctR6H3PSz5H719jF1Nh730lWhYtAzZ2UboowfjB4IuY/edit?tab=t.0" target="_blank" className="regular-link" rel="noreferrer">here</a></p>

            <p>To maximize the potential of LinkedIn for Coolbet, focusing on three key areas:</p>

            <ol>
                <li><strong>SEO Optimization:</strong>
                    <ul>
                        <li><strong>Boosting SEO:</strong> Utilize LinkedIn's high domain authority and relevant
                            keywords to improve Coolbet's SEO performance.
                        </li>
                        <li><strong>Inbound Links:</strong> Share valuable content on LinkedIn to attract inbound links
                            to the Coolbet website, further improving SEO.
                        </li>
                        <li><strong>Website Traffic:</strong> Drive traffic to the Coolbet website through links in
                            LinkedIn posts and profiles.
                        </li>
                    </ul>
                </li>

                <li><strong>Recruitment:</strong>
                    <ul>
                        <li><strong>Employer Branding:</strong> Showcase Coolbet's employer brand and company culture
                            through engaging content, including photos and videos of corporate events.
                        </li>
                        <li><strong>Attracting Talent:</strong> Highlight the talented individuals working at Coolbet
                            and showcase how the company values and appreciates its employees.
                        </li>
                        <li><strong>Authenticity:</strong> Share genuine employee experiences and stories to attract
                            potential hires in a competitive job market.
                        </li>
                    </ul>
                </li>

                <li><strong>Employee Engagement:</strong>
                    <ul>
                        <li><strong>Recognition and Appreciation:</strong> Publicly acknowledge and appreciate
                            employees' talents and contributions, fostering a sense of community and belonging.
                        </li>
                        <li><strong>Motivation and Retention:</strong> Enhance employee motivation and retention by
                            showcasing their achievements and celebrating their successes.
                        </li>
                        <li><strong>Employee Advocacy:</strong> Encourage employees to share company content on their
                            personal LinkedIn profiles, expanding reach and amplifying the company message.
                        </li>
                    </ul>
                </li>
            </ol>

            <p><strong>Key Recommendations:</strong></p>
            <ul>
                <li>Increase Posting Frequency: Increase the frequency of high-quality posts on LinkedIn to maximize
                    benefits.
                </li>
                <li>Ensure Privacy Compliance: Adhere to privacy guidelines and obtain employee consent before sharing
                    any personal information.
                </li>
                <li>Promote Employee Sharing: Encourage employees to share company content and their experiences on
                    LinkedIn.
                </li>
            </ul>
        </div>
        ,
    },
    {
        type: 'SUGGESTION',
        title: 'Office Bulletin Board',
        author: 'Ekaterina Sulima',
        category: 'Office',
        idea: <div>
            <p>
                It would be nice to have a bulletin board/cork board/poster board in the office (kitchen or reception
                area) where we can display a variety of things such as:
            </p>
            <ul>
                <li>Posters of upcoming office events</li>
                <li>"Missing" posters</li>
                <li>Random Telliskivi party poster that our employee is DJ'ing at</li>
                <li>Any other memos, notes, or pictures we can think of</li>
            </ul>
        </div>

        ,
        answer: <div><p>Great idea!</p></div>,
    },
    {
        type: 'SUGGESTION',
        title: 'Office Olympics',
        author: 'Henri Mikkola',
        category: 'Office',
        idea: <div>
            <p>Office Olympics. Team building event which would consist of several smaller events. Events could be
                almost anything but a variety of different kinds of things would be key to have something interesting
                for everyone.</p>
            <p>Events could include:</p>
            <ul>
                <li>Week-long steps challenge</li>
                <li>Sports quiz</li>
                <li>Ugliest Christmas sweater competition</li>
            </ul>
            <p>Event winners would receive small prizes, and the winning department could receive a prize or at least
                bragging rights.</p>
        </div>
        ,
        answer: <div><p>Great idea!</p></div>,
    },
]

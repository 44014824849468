import React from 'react';
import styles from './ProjectShinka.module.css'
import classNames from "classnames";
import {pageTitleStore} from "../stores/stores";
import Hero from "../components/hero/Hero";
import SubmissionCard from "../components/projectShinka/SubmissionCard";
import {SUBMISSIONS} from "../content/submissions";

const subTitle = `"Shinka" (進化) is a Japanese word that translates to "evolution" or "progress"`;

const ProjectShinkaPage = () => {
    pageTitleStore.set('Project Shinka')

    return (
        <>
            <Hero title="Project Shinka" subTitle={subTitle} />
            <div className={classNames('container', 'mainContent', 'flexGrow', styles.wrapper)}>
                <h2>Hey Cool People!</h2>

                <p>
                    Tired of lengthy processes or have issues that need fixing? Ready to ditch the "meh" and unlock a whole new level of awesome? <span className={styles.bold}>Project Shinka</span> is here, and it's your chance to be a game-changer!
                </p>
                <p>
                    The goal is to create a cooler experience for everyone - us and our customers! All you need to do is make the first step - don't wait for others to do it on your behalf.
                </p>

                <ol>
                    <li><span className={styles.bold}>Spill the Tea!</span> Tell us what's bugging you, what could be better, and what makes you go "hmm..."
                        We've got a <a className="regular-link" href="https://forms.gle/fhBAhFzE5MGBZ3obA"
                                       target="_blank" rel="noreferrer">super easy form</a> with categories for everything from process
                        improvements to office
                        oddities:
                    </li>
                    <li><span className={styles.bold}>Become a Coolbet Legend!</span> In a couple of weeks we'll inform you when the first challenges have
                        been revealed on the coollab.ee website. Got a killer solution to a challenge? Share it! This is your chance to become a Coolbet hero and make a real difference.</li>
                </ol>

                <h3>
                    Why should you jump on board?</h3>

                <ul>
                  <li>You might have a problem solved by someone else. Who doesn't like delegating work ;)</li>
                  <li>Your ideas for better Coolbet might become a reality!</li>
                  <li>Reap the Rewards! We've got some seriously cool prizes up for grabs for those who crack the toughest challenges. Stay tuned for the prize reveal!</li>
                </ul>

                <p>
                So, what are you waiting for? Let's make Coolbet legendary together!
                </p>

                <p>
                    Join Project Shinka today!<a className="regular-link" href="https://forms.gle/fhBAhFzE5MGBZ3obA" target="_blank" rel="noreferrer"> Fill
                    in the form!</a>
                </p>

                <p>
                    Game on,
                </p>

                <p>
                The Coollab Team
                </p>
            </div>
        </>
    );
}

export default ProjectShinkaPage;

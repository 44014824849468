import React from 'react';
import styles from './ProjectShinka.module.css'
import classNames from "classnames";
import {pageTitleStore} from "../stores/stores";
import Hero from "../components/hero/Hero";
import SubmissionCard from "../components/projectShinka/SubmissionCard";
import {SUBMISSIONS} from "../content/submissions";

const subTitle = `"Shinka" (進化) is a Japanese word that translates to "evolution" or "progress"`;

const ShinkaSubmissionsPage = () => {
    pageTitleStore.set('Project Shinka')

    return (
        <>
            <Hero title="Shinka Submissions" />

            <div className={classNames('container', 'mainContent', 'flexGrow', styles.wrapper)}>
                <h2>Submissions</h2>

                <div className={styles.submissionsWrapper}>
                    <h3>Suggestions</h3>

                    <div className={styles.submissions}>
                        {SUBMISSIONS.filter(({type}) => type === 'SUGGESTION').map((submission) => <SubmissionCard
                            submission={submission} key={submission.title}/>)}
                    </div>
                </div>

                <div className={styles.submissionsWrapper}>
                    <h3>Issues</h3>

                    <div className={styles.submissions}>
                        {SUBMISSIONS.filter(({type}) => type === 'ISSUE').map((submission) => <SubmissionCard
                            submission={submission} key={submission.title}/>)}
                    </div>
                </div>


                <div className={styles.submissionsWrapper}>
                    <h3>Liliya's Thoughtful Takes</h3>

                    <div className={styles.submissions}>
                        {SUBMISSIONS.filter(({type}) => type === 'LILIYA').map((submission) => <SubmissionCard
                            submission={submission} key={submission.title}/>)}
                    </div>
                </div>


            </div>
        </>
    );
}

export default ShinkaSubmissionsPage;

import {Submission} from "../../content/submissions";
import {useState} from "react";
import styles from './SubmissionCard.module.css';
import classNames from "classnames";

interface Props {
    submission: Submission
}

const SubmissionCard = ({ submission }: Props) => {
    const {title, author, category, idea, answer} = submission;

    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className={styles.wrapper}>
            <div className={styles.title} onClick={() => setIsOpen(!isOpen)}>
                <h3>{title}</h3>
                <span className={styles.author}>{author}</span>
                <span className={classNames(styles.label, styles[category.toLowerCase()])}>{category}</span>
            </div>
            {isOpen && (
            <div className={styles.idea}>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSf6YRpAlJrNb_OUIGpkmBzxjKI748zzYjrTZKZe2ClgA0h6Lw/viewform" target="_blank" rel="noreferrer" className={styles.cta}>I can solve this!</a>
                {idea}
                {answer && (
                    <>
                    <h4 className={styles.coollabTitle}>Answer from Coollab</h4>
                <div className={styles.coollabAnswer}>
                {answer}
                </div>
                    </>)}
            </div>
            )}
        </div>
    )
}

export default SubmissionCard;
